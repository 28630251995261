<template>
  <div class="document-upload-wrapper">
    <div class="document-type-container">
      <h3 class="document-category-title">{{ label }}</h3>
      <CollapseCard
        v-if="
          !!documentCategoryObj.rejectReason ||
          Object.keys(invalidDocuments).length > 0
        "
        :is-alert="true"
        class="mb-2"
      >
        <h3 slot="title-text">Kommentar från handläggare</h3>
        <template slot="body-text">
          <p v-if="!!documentCategoryObj.rejectReason">
            <LinkWrapper
              :input="documentCategoryObj.rejectReason"
            ></LinkWrapper>
          </p>
          <p v-for="document in invalidDocuments" :key="document.uuid">
            <strong>{{ document.fileName }}</strong
            >:
            <LinkWrapper :input="document.message"></LinkWrapper>
            <br />
          </p>
        </template>
      </CollapseCard>
      <p class="document-category-description">{{ description }}</p>
      <DocumentsUpload
        :documents="documents"
        :downloading-id="downloadingId"
        :upload-error="uploadError"
        doc-identifier="uuid"
        :document-category="documentCategory"
        :invalid-documents="invalidDocuments"
        @addDocument="handleAddDocument"
        @removeDocument="handleRemoveDocument"
        @openFile="handleOpenFile"
        @uploadErrorModalClose="uploadError = false"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DocumentsUpload from '@/components/DocumentsUpload.vue';
import applicationService from '@/services/applicationService';
import { getFilename } from '@/utils/common';
import CollapseCard from '@/components/CollapseCard.vue';
import LinkWrapper from '@/components/LinkWrapper.vue';

export default {
  name: 'SubletDocumentsUpload',
  components: {
    DocumentsUpload,
    CollapseCard,
    LinkWrapper
  },
  props: {
    documentCategory: {
      type: String,
      default: ''
    },
    documents: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      downloadingId: null,
      uploadError: false
    };
  },
  computed: {
    ...mapState({
      application: state => state.subletApplication.application
    }),
    documentCategoryObj() {
      return this.computedDocumentsGroups[this.documentCategory] || {};
    },
    applicationId() {
      return this.application?.id;
    },
    computedDocumentsGroups() {
      return this.application?.computedDocumentsGroups || {};
    },
    invalidDocuments() {
      return this.documents
        .filter(document => document?.status === 'REJECTED')
        .reduce(
          (prev, curr) => ({
            ...prev,
            [curr.uuid]: {
              fileName: getFilename(curr.fileName),
              message: curr.rejectReason
            }
          }),
          {}
        );
    }
  },
  methods: {
    ...mapActions({
      addDocument: 'subletApplication/addDocument',
      removeDocument: 'subletApplication/removeDocument'
    }),
    async handleAddDocument(file) {
      const applicationId = this.applicationId;
      try {
        await this.addDocument({
          applicationId,
          file,
          category: this.documentCategory
        });
      } catch {
        this.uploadError = true;
      }
    },
    async handleRemoveDocument(identifier) {
      const applicationId = this.applicationId;
      await this.removeDocument({ applicationId, uuid: identifier });
    },
    async handleOpenFile(identifier) {
      const category = this.documentCategory;
      const applicationId = this.applicationId;
      this.downloadingId = identifier;
      const file = await applicationService.getFile(
        applicationId,
        identifier,
        category
      );

      const buffer = file?.buffer;
      const type = file?.type || '';

      if (!buffer) {
        return;
      }

      const binaryData = Uint8Array.from(atob(buffer), c => c.charCodeAt(0));
      const blob = new Blob([binaryData], { type });
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);

      this.downloadingId = null;
    }
  }
};
</script>
<style scoped lang="scss">
.document-upload-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .document-type-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  :deep(.documents-wrapper) {
    margin-top: 0;
  }
}

.document-category-title {
  font-weight: 600;
  font-size: 2rem;
  color: var(--color-dark);
}

.document-category-description {
  display: block;
  font-weight: 400;
  font-size: 1.6rem;
  font-style: italic;
}
.document-category-description:not(:empty) {
  margin-bottom: 0.5rem;
}
</style>
